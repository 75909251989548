// Generated by Framer (f558e44)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PNHsjwX5J"];

const serializationHash = "framer-5G5Ie"

const variantClassNames = {PNHsjwX5J: "framer-v-1f7vi8o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, WHMBOLYgQ: image ?? props.WHMBOLYgQ ?? {src: "https://framerusercontent.com/images/vjsdKa424Sim0Q0NgMFAVKfM.webp"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WHMBOLYgQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PNHsjwX5J", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1f7vi8o", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"PNHsjwX5J"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 153, intrinsicWidth: 175, pixelHeight: 306, pixelWidth: 350, sizes: "1px", ...toResponsiveImage(WHMBOLYgQ)}} className={"framer-1fwurex"} data-framer-name={"folder"} layoutDependency={layoutDependency} layoutId={"T3nOCmAzS"}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-5G5Ie[data-border=\"true\"]::after, .framer-5G5Ie [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5G5Ie.framer-pmr5if, .framer-5G5Ie .framer-pmr5if { display: block; }", ".framer-5G5Ie.framer-1f7vi8o { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 125px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 144px; }", ".framer-5G5Ie .framer-1fwurex { aspect-ratio: 1.1437908496732025 / 1; flex: none; height: 100%; overflow: visible; position: relative; width: var(--framer-aspect-ratio-supported, 143px); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5G5Ie.framer-1f7vi8o { gap: 0px; } .framer-5G5Ie.framer-1f7vi8o > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5G5Ie.framer-1f7vi8o > :first-child { margin-left: 0px; } .framer-5G5Ie.framer-1f7vi8o > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 125
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WHMBOLYgQ":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerXyrMknsAp: React.ComponentType<Props> = withCSS(Component, css, "framer-5G5Ie") as typeof Component;
export default FramerXyrMknsAp;

FramerXyrMknsAp.displayName = "Illustrations / Folder";

FramerXyrMknsAp.defaultProps = {height: 125, width: 144};

addPropertyControls(FramerXyrMknsAp, {WHMBOLYgQ: {__defaultAssetReference: "data:framer/asset-reference,vjsdKa424Sim0Q0NgMFAVKfM.webp?originalFilename=PNG+to+WEBP+conversion.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerXyrMknsAp, [])